import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getUserData } from '../pages/apiCalls';
// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accessToken = localStorage.getItem('accessToken');
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (userId) {
      dispatch(getUserData(userId));
    }
    if (!accessToken) {
      navigate('/login');
    }
  }, [navigate, accessToken, userId, dispatch]);

  return children;
}

export default AuthGuard;
