// import { useState } from 'react';
import PropTypes from 'prop-types';
// import InviteDialog from '../../../pages/InviteDialog';
// @mui
import { useSelector } from 'react-redux';
import { AppBar, Toolbar, IconButton, styled, Grid, Button, Stack } from '../../../mui';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
import DropButton from '../../../components/dropButton';

const NAV_WIDTH = 280;

const HEADER_MOBILE = 40;

const HEADER_DESKTOP = 50;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(2, 5),
  },
}));

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const userData = useSelector((state) => state.Master.user);
  const navigateToAdminDashboard = () => {
    const currentUrl = window.location.href.replace(/^(https?:\/\/)/, '');
    const modifiedUrl = currentUrl.replace(/\/home$/, '');
    const adminUrl = `https://admin-${modifiedUrl}/auth/sign-in`;
    const accessToken = localStorage.getItem('accessToken');
    // Add the access token as a query parameter to the external URL
    const adminUrlWithTokenAndUser = `${adminUrl}/?access_token=${accessToken}`;
    window.open(adminUrlWithTokenAndUser, '_blank');
  };


  return (
    <StyledRoot>
      <StyledToolbar>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <IconButton
              onClick={onOpenNav}
              sx={{
                mt: 3,
                color: 'text.primary',
                display: { lg: 'none' },
              }}
            >
              <Iconify icon="eva:menu-2-fill" />
            </IconButton>
          </Grid>
          <Grid item>
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }} spacing={1}>
              {userData?.user_type === 2 && (
                <Button
                  sx={{ minWidth: 150, boxShadow: 0 }}
                  variant="contained"
                  disableElevation
                  onClick={navigateToAdminDashboard}
                >
                  Go to Dashboard
                </Button>
              )}
              <DropButton />
            </Stack>
            {/* <Button
              variant="contained"
              endIcon={<PersonAddAltRoundedIcon />}
              sx={{ float: 'right', minWidth: 150, mt: 2, boxShadow: 0 }}
              onClick={() => setOpenDialog(true)}
            >
              Invite
            </Button> */}
          </Grid>
        </Grid>
      </StyledToolbar>
    </StyledRoot>
  );
}
