import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Drawer,
  Typography,
  Stack,
  Divider,
  LogoutIcon,
  SettingsIcon,
  Button,
  SchoolIcon,
} from '../../../mui';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import { QueriesHistory } from '../../../store/QueriesHistory';
import SettingsDialogue from '../../../pages/SettingsDialogue';
import LearnDialog from '../../../pages/LearnDialog';
import { getSettingsData } from '../../../redux/actions/SettingsActions';

const useStyles = makeStyles((theme) => ({
  simpleBarWrapper: {
    '& .simplebar-content': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .simplebar-wrapper': {
      borderRightStyle: 'none',
    },
  },
}));

const NAV_WIDTH = 280;

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav, handleThemeChange, isDarkMode, textSize, setTextSize }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const queryData = useContext(QueriesHistory);

  const isDesktop = useResponsive('up', 'lg');
  const logoutHandler = () => {
    localStorage.clear();
    queryData.resetQueryHistory();
    navigate('/');
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isDarkMode]);
  useEffect(() => {
    (async () => {
      dispatch(getSettingsData());
    })();
  }, [dispatch]);
  const settings = useSelector((state) => state.settings.settings);
 
  const [openDialog, setOpenDialog] = useState({ dialogName: '', status: false });
  const classes = useStyles();

  const onPromptCreate = (value) => {
    setOpenDialog({ dialogName: '', status: false });
    if (value) queryData.setPromptValue(value);
  };

  const renderContent = (
    <Scrollbar
      className={classes.simpleBarWrapper}
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          component="img"
          alt="logo"
          src={'assets/logo.png'}
          sx={
            isDarkMode
              ? {
                  width: 160,
                  maxHeight: '85px',
                  objectFit: 'contain',
                  padding: '5px',
                  borderRadius: 1.5,
                  flexShrink: 0,
                }
              : { width: 170, maxHeight: '85px', objectFit: 'contain', borderRadius: 1.5, flexShrink: 0 }
          }
        />
        {settings ? null : null}
      </Box>

      <Box sx={{ mb: 3, mx: 2.5 }}>
        <Button variant="contained" fullWidth onClick={() => queryData.newChatHandler()} sx={{ boxShadow: 0 }}>
          New Chat
        </Button>
      </Box>

      <NavSection />

      <Box sx={{ flexGrow: 1 }} />

      <Divider sx={{ borderBottomWidth: 2 }} />

      <Stack direction="column" spacing={2} sx={{ px: 2.5, py: 2, pb: 10, mt: 2 }}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          spacing={3}
          onClick={() => setOpenDialog({ dialogName: 'settings', status: true })}
        >
          <SettingsIcon sx={{ color: '#A4A4A4', fontSize: 18 }} />
          <Typography sx={{ color: '#A4A4A4', fontWeight: '500' }}>Settings</Typography>
        </Stack>
        {/* <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ cursor: 'pointer' }} spacing={3}>
          <AdminPanelSettingsIcon sx={{ color: '#A4A4A4', fontSize: 18 }} />
          <Typography sx={{ color: '#A4A4A4', fontWeight: '500' }}>Admin</Typography>
        </Stack> */}
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          spacing={3}
          onClick={() => setOpenDialog({ dialogName: 'learn', status: true })}
        >
          <SchoolIcon sx={{ color: '#A4A4A4', fontSize: 18 }} />
          <Typography sx={{ color: '#A4A4A4', fontWeight: '500' }}>Learn</Typography>
        </Stack>
        {/* <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          spacing={3}
          onClick={() => setOpenDialog({ dialogName: 'invite', status: true })}
        >
          <PersonAddAltRoundedIcon sx={{ color: '#A4A4A4', fontSize: 18 }} />
          <Typography sx={{ color: '#A4A4A4', fontWeight: '500' }}>Invite</Typography>
        </Stack> */}
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          spacing={3}
          onClick={logoutHandler}
        >
          <LogoutIcon sx={{ color: '#A4A4A4', fontSize: 18 }} />
          <Typography sx={{ color: '#A4A4A4', fontWeight: '500' }}>Log Out</Typography>
        </Stack>
      </Stack>
      {openDialog.dialogName === 'learn' ? (
        <LearnDialog
          openDialog={openDialog.status}
          setOpenDialog={() => setOpenDialog({ dialogName: '', status: false })}
          onPromptCreate={onPromptCreate}
        />
      ) : // ) : openDialog.dialogName === 'invite' ? (
      //   <InviteDialog
      //     openDialog={openDialog.status}
      //     setOpenDialog={() => setOpenDialog({ dialogName: '', status: false })}
      //   />
      openDialog.dialogName === 'settings' ? (
        <SettingsDialogue
          openDialog={openDialog.status}
          setOpenDialog={() => setOpenDialog({ dialogName: '', status: false })}
          handleThemeChange={handleThemeChange}
          textSize={textSize}
          setTextSize={setTextSize}
        />
      ) : null}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
